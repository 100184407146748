import Link from "next/link";
import MegaMenu from "@referralexchange/rea-ui-react/components/navigation/MegaMenu";
import HeadingWithLinks from "@referralexchange/rea-ui-react/components/navigation/HeadingWithLinks";
import Content from "@referralexchange/rea-ui-react/atoms/Content";

import HouseIcon from "@referralexchange/rea-ui-react/svgs/house";
import EnvelopeIcon from "@referralexchange/rea-ui-react/svgs/envelope";
import LightbulbIcon from "@referralexchange/rea-ui-react/svgs/lightbulb";
import ChatBubblesIcon from "@referralexchange/rea-ui-react/svgs/chat-bubbles";
import BookOpenIcon from "@referralexchange/rea-ui-react/svgs/book-open";
import BubbleWithQuestionMarkIcon from "@referralexchange/rea-ui-react/svgs/bubble-with-question-mark";
import CommunityWithHeart from "@referralexchange/rea-ui-react/svgs/nav/community-with-heart";
import MainNav from "@referralexchange/rea-ui-react/components/navigation/MainNav";

import { MEGA_MENU_ABOUT } from "~/config/funnel";

import dynamic from "next/dynamic";
import { Suspense } from "react";
const CtaButton = dynamic(() => import("~/components/CtaButton"), {
  ssr: false,
});
export default function AboutMegaMenu() {
  const leftCTA = (
    <div className={MegaMenu.styles.cta}>
      <CommunityWithHeart title="Community icon" />
      <Content className={Content.styles.medium}>Get Started Today</Content>
      <Content
        className={[Content.styles.body14, Content.styles.center].join(" ")}
      >
        Discover the best agents in your local market
      </Content>
      <Suspense>
        <CtaButton funnel={MEGA_MENU_ABOUT}>Connect Today</CtaButton>
      </Suspense>
    </div>
  );

  const centerItems = [
    <Link href="/about">
      <span className={HeadingWithLinks.styles.icon}>
        <HouseIcon />
      </span>
      About Us
    </Link>,
    <Link href="/real-estate-guides">
      <span className={HeadingWithLinks.styles.icon}>
        <LightbulbIcon />
      </span>
      Guides
    </Link>,
    <Link href="/contact">
      <span className={HeadingWithLinks.styles.icon}>
        <EnvelopeIcon />
      </span>
      Contact
    </Link>,
    <Link href="/reviews">
      <span className={HeadingWithLinks.styles.icon}>
        <ChatBubblesIcon />
      </span>
      Reviews
    </Link>,
    <Link href="/blog">
      <span className={HeadingWithLinks.styles.icon}>
        <BookOpenIcon />
      </span>
      Blog
    </Link>,
    <Link href="/how-it-works">
      <span className={HeadingWithLinks.styles.icon}>
        <BubbleWithQuestionMarkIcon />
      </span>
      How it Works
    </Link>,
  ];
  return (
    <MegaMenu
      label="About"
      left={leftCTA}
      center={
        <HeadingWithLinks
          items={centerItems}
          className={MainNav.styles.twoColumn}
        />
      }
    />
  );
}
